import React, {useState} from 'react';
import { Layout } from 'layouts';
import { useTranslation } from 'react-i18next';
import getHeaderLInks from '../shared/headerLinks';
import Constants from '../../static/Constants';
import Helmet from 'react-helmet';
import Button from '@material-ui/core/Button';
// import { navigate } from 'gatsby';
import { withStyles, makeStyles } from '@material-ui/core';
import { Container } from '@material-ui/core';
import VerticalFixedNav from '../styles/verticalFixedNav';
import getStaticImageURL from '../shared/getStaticImageURL';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import GridItem from '../components/Grid/GridItem.jsx';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Document, Page, pdfjs } from 'react-pdf';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import IconButton from '@material-ui/core/IconButton';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#2c3b8f',
    color: theme.palette.common.white,
    paddingLeft: '10px !important',
    paddingRight: '10px !important',
    fontFamily: `"Poppins", sans-serif`,
  },
  body: {
    fontSize: 14,
    paddingLeft: '10px !important',
    paddingRight: '10px !important',
    fontFamily: `"Poppins", sans-serif`,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles(theme => ({
  wwhTitle: {
    fontSize: '36px',
    color: '#151515',
    marginTop: '30px',
    marginBottom: '30px',
    fontWeight: 700,
    textAlign: 'center',
    lineHeight: '50px',
  },
  wwhSubtitle: {
    fontSize: '24px',
    color: '#656363',
    fontWeight: 700,
    marginBottom: '15px',
  },
  wwhConentContainer: {
    paddingTop: '20px',
    paddingBottom: '20px',
  },
  organisers: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  organiserLogo: {
    width: '165px',
    marginLeft: '10px',
    marginRight: '10px',
  },
  table: {
    // minWidth: 700,
    fontFamily: `"Poppins", sans-serif`,
  },
  lastDate: {
    fontStyle: 'italic',
    backgroundColor: 'yellow',
    fontSize: '20px',
    fontWeight: '600',
  },
  fao: {
    fontFamily: 'unset',
    fontWeight: 'bold',
    color: '#0059AA',
    fontSize: '20px',
  },
  faoText: {
    marginLeft: '35px',
    marginTop: '-25px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(3),
      marginTop: theme.spacing(-2),
      marginBottom: theme.spacing(2),
      fontSize: '12px',
    },
  },
  projectsList: {
    listStyleType: 'none', 
    paddingLeft: '1em',
    '& li::before': {
      content: '"- "',
      marginRight: '0.5em', 
    },
  },
}));

const WWHConference = ({ ...props }) => {
  const { VerticalFixedNavStyles } = VerticalFixedNav;
  const classes = useStyles();
  const { t, ready } = useTranslation();
  const { pageContext, location } = props;
  const data = pageContext.pageData ? pageContext.pageData : null;
  if (!data) {
    return;
  }
  const programLink = pageContext.programUrl;
  const headerLinks = getHeaderLInks('', programLink, data.noLinks);
  const { pageHeader, headerLogo, headerLogoScroll } = (data || {}).node || {};
  const logos = pageContext.logos || [];
  const footerLogos = pageContext.footerLogos || [];
  const helmetLink = `${Constants.appUrl}/wwh-conference`;
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfLoader, setPdfLoader] = useState(true);
  const folderPath = 'resources/WWHC/';
  const baseURL = Constants.apiUrl;

  const homePageInformation =
    t('wwhConferenceThankYou', {
      returnObjects: true,
    }) || [];

  const getPageDetails = () => {
    if (!ready) {
      return 'Loading...';
    } else {
      return homePageInformation;
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
    setPdfLoader(false);
  };

  const changePage = offset => {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };
  const generatePdfView = () => {
    return (
      <GridItem sm={12} md={12}>
        {mobileScreen ? (
          <div className="pdf-sector">
            <a href={`${baseURL}/${folderPath}${getPageDetails()?.information?.summary?.pdfLink}`} target="_blank" rel="noreferrer">
              <Button
                variant="contained"
                size="large"
                className="btn-primary"
                // onClick={() => downloadPdf(getLinkByName(url))}
              >
                {t('download')} PDF
              </Button>
            </a>
          </div>
        ) : (
          <div>
            {!pdfLoader && (
              <>
                <div className="pdftoolbar">
                  <div>
                    <IconButton
                      className="arrowBack"
                      disabled={pageNumber <= 1}
                      onClick={previousPage}
                    >
                      <ArrowBackIos />
                    </IconButton>
                    <span className="pdf-pagination">
                      {pageNumber || (numPages ? 1 : '--')} / {numPages || '--'}{' '}
                    </span>
                    <IconButton
                      disabled={pageNumber >= numPages}
                      onClick={nextPage}
                    >
                      <ArrowForwardIosIcon />
                    </IconButton>
                  </div>
                  <div>
                    <a
                      href={`${baseURL}/${folderPath}${getPageDetails()?.information?.summary?.pdfLink}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button
                        className="btn-primary"
                        variant="contained"
                      >
                        {t('download')} PDF
                      </Button>
                    </a>
                  </div>
                </div>
                <hr className="horizonal-line" />
              </>
            )}
            <Document
              file={`${baseURL}/${folderPath}${getPageDetails()?.information?.summary?.pdfLink}`}
              onLoadSuccess={onDocumentLoadSuccess}
              className="docPdf"
            >
              <Page pageNumber={pageNumber} renderTextLayer={false} />
            </Document>
          </div>
        )}
      </GridItem>
    );
  };

  return (
    <Layout
      programLink={programLink}
      title={getPageDetails()?.title}
      content={[getPageDetails()?.subtitle]}
      headerLinks={headerLinks}
      logos={logos}
      footerLogos={footerLogos}
      pageHeader={pageHeader}
      headerLogo={headerLogo}
      headerLogoScroll={headerLogoScroll}
      imageName={'wwh-parallax.jpg'}
      visitedLinks={[{ url: '/', key: 'home', name: 'Home' }]}
      currentPage={{ key: 'wwhConference' }}
      bannerLogoImage="wwh-logo-tp.png"
      bannerLogoLink="https://www.unido.org/world-without-hunger-conference"
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>WWH Conference</title>
        <link rel="canonical" href={helmetLink} />
      </Helmet>
      <div className="container">
        <Container css={VerticalFixedNavStyles}>
          <div>
            <h4 className={classes.wwhTitle}>
              <p
                dangerouslySetInnerHTML={{
                  __html: getPageDetails()?.information?.title,
                }}
              ></p>
            </h4>
          </div>
          <div className={classes.wwhConentContainer}>
            <div>
            <p
                dangerouslySetInnerHTML={{
                  __html: getPageDetails()?.information?.thankYou?.content,
                }}
              ></p>
            </div>
          </div>
          <div className={classes.wwhConentContainer}>
            <div className={classes.wwhSubtitle}>
              {getPageDetails()?.information?.projects?.title}
            </div>
            <div>
              {/* {getPageDetails()?.information?.whyApplying?.content?.preText} */}
              <p
                dangerouslySetInnerHTML={{
                  __html: getPageDetails()?.information?.projects?.content
                    ?.preText,
                }}
              ></p>
              <ol>
                {getPageDetails()?.information?.projects?.content?.projectData?.map(
                  (ele, index) => {
                    // return <li key={index}>{ele}</li>;
                    return (
                      <li key={index}>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: ele?.title,
                          }}
                        ></p>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: ele?.description,
                          }}
                        ></p>
                        <ul className={classes.projectsList}>
                          <li><span dangerouslySetInnerHTML={{
                            __html: getPageDetails()?.information?.projects?.keys?.investmentOpportunitySummary,
                          }}></span><span>:&nbsp;</span><a className="links-blanks" href={ele?.investmentOpportunitySummary} target='_blank' rel="noopener noreferrer">{ele?.investmentOpportunitySummary}</a></li>
                          <li><span dangerouslySetInnerHTML={{
                            __html: getPageDetails()?.information?.projects?.keys?.companyWebsite,
                          }}></span><span>:&nbsp;</span><a className="links-blanks" href={ele?.companyWebsite} target='_blank' rel="noopener noreferrer">{ele?.companyWebsite}</a></li>
                          
                        </ul>
                      </li>
                    );
                  }
                )}
              </ol>
            </div>
          </div>
          <div className={classes.wwhConentContainer}>
            <div>
              <p dangerouslySetInnerHTML={{
                            __html: getPageDetails()?.information?.video?.data,
                          }}>
              </p>
            </div>
          </div>
          <div className={classes.wwhConentContainer}>
            <div>
              <p dangerouslySetInnerHTML={{
                            __html: getPageDetails()?.information?.explore?.data,
                          }}>
              </p>
            </div>
          </div>
          <div className={classes.wwhConentContainer}>
            <div className={classes.wwhSubtitle} style={{ fontSize: '20px' }}>
              {getPageDetails()?.information?.summary?.title}
            </div>
            <div>
              {generatePdfView()}
            </div>
          </div>
          <div className={classes.wwhConentContainer}>
            <div>
              <p dangerouslySetInnerHTML={{
                            __html: getPageDetails()?.information?.socialMedia?.data,
                          }}>
              </p>
            </div>
          </div>
          <div className={classes.wwhConentContainer}>
            <div className={classes.wwhSubtitle} style={{ fontSize: '20px' }}>
              {getPageDetails()?.information?.contact?.title}
            </div>
            <div>
              {homePageInformation?.information?.contact?.content}&nbsp;
              <a
                className="links-blanks"
                href="mailto:investmentportal@unido.org"
              >
                investmentportal@unido.org
              </a>
            </div>
          </div>
        </Container>
      </div>
    </Layout>
  );
};

export default WWHConference;
